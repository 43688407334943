import axios from 'axios';
import { store } from '../store';
const API_END_POINT = process.env.REACT_APP_API_END_POINT;
const API_END_CUSTOMER_POINT = process.env.REACT_APP_API_CUSTOMER_END_POINT;
const KYC_MINNAPAD = process.env.REACT_APP_MINNAPAD_KYC
const API_VERSION = 'api/v1/';
export function getHeader() {
  return {
    headers: {
      Authorization: store.getState().auth?.user?.token || store.getState().auth?.token
    }
  }
}
export function get(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`, getHeader());
}
export function getCustomer(url: string) {
  return axios.get(API_END_CUSTOMER_POINT + `${API_VERSION}${url}`, getHeader());
}
export function postCustProfile(url: string, obj: Object) {
  return axios.post(KYC_MINNAPAD + `${API_VERSION}${url}`, obj, getHeader());
}
export function getCustomerKyc(url: string) {
  return axios.get(API_END_CUSTOMER_POINT + `${API_VERSION}${url}`, getHeader());
}
export function getTopNft(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`, getHeader());
}
export function post(url: string, obj: Object) {
  return axios.post(API_END_POINT + `${API_VERSION}${url}`, obj, getHeader());
}
export function put(url: string, obj: Object) {
  return axios.put(API_END_POINT + `${API_VERSION}${url}`, obj, getHeader());
}
export const convertUTCToLocalTime = (dateString: any) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
  const localTime = new Date(milliseconds);
  return localTime.toISOString();
};
