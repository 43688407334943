const SET_USER_ID: string = 'setUserID';
const SELECTED_COLLECTION: any = 'selectedCollection';
const SELECTED_HOT_COLLECTION: any = 'selectedHotCollection';
const SET_TOKEN = "setToken";
const setUserID = (payload: string) => {
  return {
    type: SET_USER_ID,
    payload,
  };
};

const selectedCollection = (payload: string) => {
  return {
    type: SELECTED_COLLECTION,
    payload,
  };
};

const selectedHotCollection = (payload: string) => {
  return {
    type: SELECTED_HOT_COLLECTION,
    payload,
  };
};
const setToken = (payload) => {
  return {
      type: SET_TOKEN,
      payload
  }
};
const initialState = {
  user: { id: '', name: '' },
  selectedCollection: null,
  selectedHotCollection: null,
  token:null
};

const rootReducer = (state: any = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_ID:
      state = { ...state, user: payload };
      return state;
    case SELECTED_COLLECTION:
      state = { ...state, selectedCollection: payload };
      return state;
    case SELECTED_HOT_COLLECTION:
      state = { ...state, selectedHotCollection: payload };
      return state;
     case SET_TOKEN:
      state={...state,token:payload} ;
      return state;
    default:
      return state;
  }
};

export default rootReducer;
export { setUserID, selectedCollection, selectedHotCollection,setToken };
