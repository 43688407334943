import React, { useReducer,useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import metmaskIcon from '../assets/images/connectwallet.svg';
import errorimg from '../assets/images/error.svg';
import walletIcon from '../assets/images/walletconnect.svg';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { switchNetwork } from 'wagmi/actions';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case "SET":
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
const WalletConnect = ({ showWalletModal, onWalletConect, onWalletClose, onWalletError }: IWalletConnection) => {
    const { connectAsync, connectors, error } = useConnect();
    const { isConnected } = useAccount();
    const { disconnectAsync } = useDisconnect()
    const [state, dispatch] = useReducer(reducer, { icons: { metaMask: metmaskIcon, walletConnect: walletIcon } });
    const [walletError, setWalletError] = useState(null);
    const [scuess, setSucess] = useState(false);
    const handleConnect = async (connector: any) => {
        try {
            if (connector.id === "walletConnect") {
                onWalletClose();
            }
            if (!isConnected) {
                const { account, chain } = await connectAsync({ connector });
                if (chain.id != process.env.REACT_APP_CHAIN_ID_NUMARIC) {
                    await switchNetwork({ chainId: process.env.REACT_APP_CHAIN_ID_NUMARIC });
                    onWalletConect(account);
                    connector.onAccountsChanged = onAccountsChanged;
                    connector.onChainChanged = onChainChanged;
                    onWalletClose();
                } else {
                    onWalletConect(account);
                    onWalletClose();
                    connector.onAccountsChanged = onAccountsChanged;
                    connector.onChainChanged = onChainChanged;
                }

            } else {
                onWalletClose();
                connector.onAccountsChanged = onAccountsChanged;
                connector.onChainChanged = onChainChanged;
            }
        } catch (error) {
            if (onWalletError)
                onWalletError(error);
                setWalletError(error?.message);
                setSucess(true);
                setTimeout(()=>(setSucess(false),setWalletError(null)),2000)
        }
    }
    const onAccountsChanged = (account) => {
        onWalletConect(account);
       window.location.reload();
    }
    const onChainChanged = async () => {
        await disconnectAsync();
       window.location.reload();
    }
    return <React.Fragment>
        <Modal
            show={showWalletModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="wallet-popup"
        >
            <div className='modal-header'>
                <span className="icon md close-icon c-pointer modal-position" onClick={onWalletClose}></span>
            </div>
            <Modal.Body className="text-center">
                {/* {error && (
                    <div className='cust-error-bg text-start'>
                        <div className='cust-crd-mr'><Image src={errorimg} alt="" /></div>
                        <div>
                            <p className='error-title error-red'>Error</p>
                            <p className="error-desc">{error?.message}</p></div>
                    </div>
                )} */}
                <h2 className="custom-title">Connect Your Wallet</h2>
                <hr className='top-seller-hr text-center'></hr>
                <p className="custom-text">
                    Connect with one of available wallet providers or create a new wallet.
                </p>
                {connectors.map(connector => <> {connector.ready && <div className="walletconnect-bg c-pointer meta-btn-style btn-primary pop-cards" onClick={() => { handleConnect(connector) }} key={connector.id}>
                    <Image src={state.icons[connector.id] || metmaskIcon} alt="" />
                    <p className="mb-0 c-pointer">
                        {connector.name}
                    </p>
                </div>}</>)}
                <p className="custom-text mb-2">
                    we do not own private keys and cannot
                    access your funds without your confirmation
                </p>
                <p className='note mt-4'><b>Note: If you encounter difficulties connecting your wallet, please refresh your browser and try again.</b></p>
            </Modal.Body>
        </Modal>
        <div className='p-absolute toaster-center'>
        <ToastContainer className="p-3 cust-nametoster position-fixed bottom-0 " >
          <Toast show={scuess} className="text-center toster-component">
            <Toast.Body className="toaster-cust">
             <span className='icon md error me-2'></span> <span>{walletError}</span>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </React.Fragment>
}
export default WalletConnect;

interface IWalletConnection {
    showWalletModal: boolean
    onWalletClose: Function,
    onWalletConect: Function,
    onWalletError?: Function
}