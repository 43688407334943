import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import logoWhite from '../../assets/images/Minnapad-Logos_A.svg';
import discord from '../../assets/images/discord.svg';
import twitter from '../../assets/images/twitter.svg';
import telegram from '../../assets/images/telegram.svg';
import facebook from '../../assets/images/facebook.svg';
import tiktok from '../../assets/images/tiktok.svg';

const Footer = () => {
  return (<div className='container-fluid footer-greybg footer-topspace'>
    <div className="container footer-content">
      {/* <div className="circle-section">
        <div className="footr-content">
          <h4 className="foter-title">Join the community</h4>
          <div className="smmftr-icons">
            <a href="https://dexsport.io/" target="_blank" className="icon md mike"></a>
            <a href="https://www.facebook.com/" target="_blank" className="icon md fb"></a>
            <a href="https://twitter.com/login?lang=en" target="_blank" className="icon md tw"></a>
            <a href="https://web.telegram.org/" target="_blank" className="icon md tlgrm"></a>
            <a href="https://www.instagram.com/" target="_blank" className="icon md insta"></a>
          </div>
        </div>
      </div> */}
      <div className='row'>
        <div className="col-lg-3 col-md-3 mb-footer-align">
        <Image src={logoWhite} alt="logo" className="cust-logo footer-logo" />
        <p className='footer-desc'>Create-to-Earn with legendary Japanese IP makers!</p>
        </div>
        <div className="col-lg-3 col-md-3  col-pl-align">
          <h4 className='footer-title'>Home</h4>
          <p className='mb-2'><a href="https://minnapad.com/#active_dao" target='_blank'>Active DAOs</a></p>
          <p className='mb-2'><a href="https://minnapad.com/#upcoming_dao" target='_blank'>Upcoming DAOs</a></p>
          <p className='mb-2'><a href="https://minnapad.com/#latest_news" target='_blank'>Latest News</a></p>
          <p className='mb-2'><a href="https://minnapad.com/#partner" target='_blank'>Partners</a></p>
          <p className='mb-2'><a href="https://minnapad.com/#media" target='_blank'>Media</a></p>
        </div>
        <div className="col-lg-3 col-md-3 col-2-pl-align">
          <h4 className='footer-title'>Company</h4>
          <p className='mb-2'><a href="https://minnapad.com/company/" target='_blank'>About Minnapad</a></p>
          <p className='mb-2'><a href="https://minnapad.com/company/#meet_the_crew" target='_blank'>Meet The Crew</a></p>
          <p className='mb-2'><a href="https://minnapad.com/company/#join_our_team" target='_blank'>Join Us</a></p>
          <p className='mb-2'><a href="https://minnapad.com/inquiries" target='_blank'>Support</a></p>
        </div>
        <div className="col-lg-3 col-md-3">
          <h4 className='footer-title'>Legal</h4>
          <p className='mb-2'><a href="https://minnapad.com/terms-and-conditions/" target='_blank'>Terms and Conditions</a></p>
          <p className='mb-2'><a href="https://minnapad.com/privacy-policy/" target='_blank'>Privacy Policy</a></p>
          <p className='mb-2'><a href="https://minnapadcom.medium.com/" target='_blank'>News/Articles</a></p>
          <p className='mb-2'><a href="https://minnapad.com/wp-content/uploads/2023/05/Whitepaper-Minnapad.pdf" target='_blank'>Whitepaper</a></p>
        </div>
      </div>
      <div className='row pt-5 align-items-center'>
        <div className='col-lg-8 col-md-8 footer-copy'>Copyright © 2023 Minnapad. All Rights Reserved.</div>
        <div className='col-lg-4 col-md-4'>
          <ul className='footer-icons'>
            <li><a href='https://discord.com/invite/fRJAY3dMbq' target='_blank'><img src={discord} alt="" /></a></li>
            <li><a href='https://twitter.com/minnapadcom' target='_blank'><img src={twitter} alt="" /></a></li>
            <li><a href='https://t.me/Minnapad' target='_blank'><img src={telegram} alt="" /></a></li>
            <li><a href='https://www.facebook.com/minnapadcom' target='_blank'><img src={facebook} alt="" /></a></li>
            <li><a href='https://www.tiktok.com/@minnapadsg' target='_blank'><img src={tiktok} alt="" /></a></li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Footer;
