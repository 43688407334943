import React from 'react';
import loadimg from '../assets/images/Minnapad-Logo-loader.svg';
import Image from 'react-bootstrap/Image';
const CompleteKyc = (props : any) => {
  const handleKyc = () => {
    window.open(process.env.REACT_APP_KYC_APP, "_blank")
  };

  return (
    <div className="container">
       {props?.loader && 
            <>
             <div className="d-flex justify-content-center">
               <div className='loading-overlay'>
               <div className="text-center image-container">
               <Image
                         className=""
                         src={loadimg}
                         alt=""
                       ></Image>
             </div></div>
             </div>
            </>}
     {!props?.loader && <div className="proceedKyc staking-steps kyc-centeralign">
        <div className="text-center">
          <h3 className='completekyc-title mb-4'>To proceed with Marketplace <br/> please <span style={{color:'#0068ff'}}>complete your KYC</span></h3>
          <hr className='my-collection-hr'/>
          <button type="button" className="custom-btn" onClick={handleKyc}>
            Complete KYC
          </button>
        </div>
      </div>}
    </div>
  );
};

export default CompleteKyc;
