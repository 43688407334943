import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import Header from "./header";
import React from "react";
import WalletCheck from "../components/wallet.check.component";
import { Spinner } from "react-bootstrap";
import { getCustomer } from "../utils/api";
import { store } from "../store";
import { setToken } from "../reducer/rootReducer";
import Footer from "./Footer/Footer";
import { Provider } from "react-redux";
import { useSelector } from 'react-redux';
import Image from 'react-bootstrap/Image';
import loadimg from '../../src/assets/images/Minnapad-Logo-loader.svg';


function Routers() {

    const customerId = useSelector((state: any) => state.auth.user.id);
    const Dashboard = React.lazy(() => import("../components/dashboard.component"));
    const CreateSingleNft = React.lazy(() => import("../components/createnft.component/index"));
    const ExploreNfts = React.lazy(() => import("../components/explorenfts.component/index"))
    const DetailPage = React.lazy(() => import("../components/detailpage.component/index"))
    const CreatedBy = React.lazy(() => import("../components/templates/home/createdby"))
    const MyCollections = React.lazy(() => import("../components/createcollection.component/myCollections"))
    const Creator = React.lazy(() => import("../components/templates/home/creator"))
    const CreateCollection = React.lazy(() => import("../components/createcollection.component/createcollection"))
    const Profile = React.lazy(() => import("../components/profile.component/index"));
    const Pageerror = React.lazy(()=> import("../components/pagenotfound"));
    const routes = createBrowserRouter([
        {
            path: "/",
            element: <>
                <Header />
                <WalletCheck>
                    <Outlet />
                </WalletCheck>
                <Footer />
            </>,
            loader: async () => {
                const id = customerId;
                return getCustomer(`User/GetAuthorizationToken/${id ? id : ""}`).then(res => {
                    store?.dispatch(setToken(res.data));
                    return res.data
                })
            },
            children: [
                {
                    path: "dashboard",
                    element: <React.Suspense fallback={<div className="text-center">

                        <div className='loading-overlay'>
                            <div className="text-center image-container">
                                <Image
                                    className=""
                                    src={loadimg}
                                    alt=""
                                />
                            </div></div>
                    </div>}><Dashboard /></React.Suspense>
                },
                // {
                //     path: "create/single",
                //     element: <React.Suspense fallback={<div className="text-center">
                //         <div className='loading-overlay'>
                //             <div className="text-center image-container">
                //                 <Image
                //                     className=""
                //                     src={loadimg}
                //                     alt=""
                //                 />
                //             </div></div>
                //         {/* <Spinner /> */}
                //     </div>}> <CreateSingleNft /></React.Suspense>
                // },
                {
                    path: "explorenfts",
                    element: <React.Suspense fallback={<div className="text-center">
                        <div className='loading-overlay'>
                            <div className="text-center image-container">
                                <Image
                                    className=""
                                    src={loadimg}
                                    alt=""
                                />
                            </div></div>
                    </div>}> <ExploreNfts /></React.Suspense>
                },
                {
                    path: `assets/:tokenId?/:collectionAddress?/:nftId`,
                    element: <React.Suspense fallback={<div className="text-center"></div>}> <DetailPage /></React.Suspense>
                },
                {
                    path: "/", element: <Navigate to="/dashboard" />
                },
                {
                    path: "accounts/:useraddress?",
                    element: <React.Suspense fallback={<div className="text-center"></div>}><CreatedBy /></React.Suspense>
                },
                {
                    path: "mycollections",
                    element: <React.Suspense fallback={<div className="text-center">
                        <div className='loading-overlay'>
                            <div className="text-center image-container">
                                <Image
                                    className=""
                                    src={loadimg}
                                    alt=""
                                />
                            </div></div>
                    </div>}><MyCollections /></React.Suspense>
                },
                {
                    path: "collections/:collectionId?",
                    element: <React.Suspense fallback={<div className="text-center"></div>}><Creator /></React.Suspense>
                },
                // {
                //     path: "createcollection/:token?",
                //     element: <React.Suspense fallback={<div className="text-center"><Spinner /></div>}><CreateCollection /></React.Suspense>
                // },
                {
                    path: "profile",
                    element: <React.Suspense fallback={<div className="text-center">
                        <div className='loading-overlay'>
                            <div className="text-center image-container">
                                <Image
                                    className=""
                                    src={loadimg}
                                    alt=""
                                />
                            </div></div>
                    </div>}><Profile /></React.Suspense>
                },
            ]
        },
        {
            path: "/", element: <Navigate to="/dashboard" />
        },
        {
           path: "*",
            element: <React.Suspense fallback={<div className="text-center"></div>}><Pageerror /></React.Suspense>
        },
    ]);
    return (
        <Provider store={store}>

            <RouterProvider router={routes} />

        </Provider>
    );
}
export default Routers;